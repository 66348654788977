/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTablePrintCell from "layouts/cad/calibrationReports/DataTablePrintCell";

// Firebase
import { getAuth, getIdToken } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

// Axios
import Axios from "axios";
import MDAlert from "components/MDAlert";

function DataTables() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const auth = getAuth();
  const [user] = useAuthState(auth);

  const fetchData = () => {
    getIdToken(user).then((idToken) => {
      Axios.get(
        `${API_URL}CertificateOfCalibration/GetCertificateOfCalibrationListClient/?time=${new Date().getTime()}`,
        {
          headers: {
            Authorization: `Bearer  ${idToken}`,
          },
        }
      )
        .then((response) => {
          const temp = response.data.rowModels.map((item) => {
            //item.VCertUrl = `${process.env.REACT_APP_API_URL}report/cert/?certId=${item.id}&guid=${item.vReportGuid}`;
            return item;
          });

          const dataTableData = {
            columns: [
              { Header: "Certificate Number 1", accessor: "certificateNumber", width: "20%" },
              { Header: "UUC SN", accessor: "vEquipmentSerialNo", width: "10%" },
              { Header: "UUC Tag No", accessor: "vEquipmentTagNo", width: "10%" },
              { Header: "Date", accessor: "vDateOfCalibration", width: "10%" },
              { Header: "Due Date", accessor: "vDueDateOfCalibration", width: "10%" },
              {
                Header: "Print",
                accessor: "vCertLink",
                width: "25%",
                Cell: ({ value }) => DataTablePrintCell({ url: value }),
              },
            ],
            rows: temp
          };
          setData(dataTableData);
          setTotal(response.data.total);
          setIsLoading(false);
        }).catch(error => {
          console.log(error);
        });
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(total > 0 && !isLoading) &&
        <MDBox pt={6} pb={3}>
          <MDBox mb={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Calibration Reports
                </MDTypography>
              </MDBox>
              {data &&
                <DataTable table={data} canSearch />
              }
            </Card>
          </MDBox>
        </MDBox>
      }

      {(total === 0 && !isLoading) &&
        <MDAlert color="warning">
          <MDTypography variant="body2" color="white">
            No records found.
          </MDTypography>
        </MDAlert>
      }

      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
